<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :radioListItem="radioButtonReportISC"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ReportTableSQC',
  data () {
    return {
      radioButtonReportISC: [
        {
          id: 1,
          label: 'protocolsSQC',
          value: 'sqc-report-protocol'
        },
        {
          id: 2,
          label: 'statementSQC',
          value: 'sqc-report-statement'
        }
      ],
      actions: [
        {
          id: 2,
          tooltip: 'tooltip.go',
          to: (item) => ({ name: 'sqc-protocols-info', params: { id: item.sailor.id, documentID: item.id }, query: { ...this.$route.query } }),
          target: '_blank',
          color: 'blue',
          name: 'mdi-arrow-right',
          checkAccess: (item) => item.id
        }
      ],
      headers: [
        { value: 'sailor.id', text: this.$t('sailorId') },
        { value: 'number_document', text: this.$t('protocol') },
        { value: 'branch_create.name_ukr', text: this.$t('affiliate') },
        { value: 'statement_dkk', text: this.$t('statement') },
        { value: 'sailorFullName', text: this.$t('fullName') },
        { value: 'sailor.birth_date', text: this.$t('dateBorn') },
        { value: 'decision.name_ukr', text: this.$t('solution') },
        { value: 'rank', text: this.$t('rank') },
        { value: 'position', text: this.$t('position') },
        { value: 'is_experience_required', text: this.$t('experience') },
        { value: 'ces_exam', text: this.$t('ces_exam') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listProtocolsSqc,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListProtocolsSQC']),
    async getData (params) {
      await this.getListProtocolsSQC({ ...this.$route.params, ...params })
    }

  }
}
</script>
